import { Table, Modal, Input, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchUsers,
  updateToken,
  stopTrade,
  stopAllTrade,
  clossAllOpenPostions,
} from "./../API/dispatch";
import { Users } from "./../helpers/columns";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import io from "socket.io-client";

//dayjs Extend
dayjs.extend(utc);

export function Report({ ...props }) {
  const { userData, setAuth, startDate, endDate, setStartDate, setEndDate } =
    props;

  const [data, setData] = useState(userData);
  const [level, setLevel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [row, setRow] = useState([]);
  const params = useParams();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async function fetch() {
      setLoading(true);
      const data = await fetchUsers({ ...params, startDate, endDate });
      if (data) {
        setData(data);
        setLevel(params.level);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [params, startDate, endDate]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_PL_URL, {
      transports: ["websocket"], // You can specify the transport to use
    });
    socket.on("pnl", (data) => {
      if (data.length) {
        setData(data);
        const t = data.map((i) => i?.total || 0);
        const sum = t.reduce((a, b) => a + b, 0);
        setTotal(sum);
      }
    });

    return () => {
      socket.disconnect(); // Clean up the socket connection when the component unmounts
    };
  }, []);

  const handleUpdateToken = (data) => {
    setRow(data);
    setIsModalOpen(true);
  };

  const handleStopTrade = (data) => {
    Modal.confirm({
      title: "Stopping Trade",
      content: "Are you sure stop Trade for " + data.username,
      okText: "ok",
      cancelText: "Cancel",
      onOk: async () => {
        await stopTrade({
          id: data._id,
        });
        const u = await fetchUsers({ ...params, startDate, endDate });
        if (u) {
          setData(u);
          setLoading(false);
        }
        setLoading(false);
      },
    });
  };

  const handleStopAll = () => {
    Modal.confirm({
      title: "Stopping All Trade",
      content: "Are you sure stop Trade!",
      okText: "ok",
      cancelText: "Cancel",
      onOk: async () => {
        await stopAllTrade();
        const u = await fetchUsers({ ...params, startDate, endDate });
        if (u) {
          setData(u);
          setLoading(false);
        }
        setLoading(false);
      },
    });
  };

  const handleClossAll = () => {
    Modal.confirm({
      title: "Closing All Open Postions",
      content: "Are you sure close all positions!",
      okText: "ok",
      cancelText: "Cancel",
      onOk: async () => {
        await clossAllOpenPostions();
        const u = await fetchUsers({ ...params, startDate, endDate });
        if (u) {
          setData(u);
          setLoading(false);
        }
        setLoading(false);
      },
    });
  };

  const TableHeaders = () => {
    return (
      <>
        <Button className="exit" onClick={handleStopAll}>
          Stop All
        </Button>
        <Button className="close" onClick={handleClossAll}>
          Close All Open Postions
        </Button>
        <h4 style={{ marginLeft: "50%" }}>
          Total:{" "}
          <span style={{ color: `${total > 0 ? "green" : "red"}` }}>
            {total.toLocaleString("en-IN")}
          </span>
        </h4>
      </>
    );
  };

  return (
    <div
      className="project-container"
      style={{ maxHeight: "100vh", background: "white" }}
    >
      <Table
        title={TableHeaders}
        pagination={false}
        className={"table"}
        sticky={true}
        rowKey={"_id" || "username"}
        columns={Users(handleUpdateToken, handleStopTrade)}
        dataSource={data}
        scroll={{ y: true }}
        bordered={true}
        loading={loading}
      />

      <Modal
        title={"Update the Token for -> " + row?.username?.toUpperCase()}
        open={isModalOpen}
        destroyOnClose
        onOk={async () => {
          await updateToken({
            id: row._id,
            token: token.replace(/^"(.*)"$/, "$1"),
          });
          setToken("");
          const u = await fetchUsers({ ...params, startDate, endDate });
          if (u) {
            setData(u);
            setLoading(false);
            setIsModalOpen(false);
          }
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <Input
          className="model-input"
          placeholder="Token"
          type="text"
          onChange={(tok) => setToken(tok.target.value)}
        />
      </Modal>
    </div>
  );
}

export default Report;
